.video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-header {
    margin: 32px;
    width: 80%;
    border-radius: 20px;
    font-size: 32px;
    text-align: center;
    background-color: gainsboro;
    color: white;
}

@media (max-width: 850px) {
    .product-container {
        flex-direction: column;
    }
}